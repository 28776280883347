.App {
  font-family: sans-serif;
  text-align: center;
}
.myStyle{
  width: 650px;
  margin-top: 50px;
}
@font-face {
  font-family: "Hacen Liner Print-out" ;
  src: url(./components/fonts/HacenLinerPrint-out.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen Bd" ;
  src: url(./components/fonts/HacenLinerScreenBd.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen" ;
  src: url(./components/fonts/HacenLinerScreen.ttf);
}
@font-face {
  font-family: "Hacen Liner XL" ;
  src: url(./components/fonts/HacenLinerXL.ttf);
}
@font-face {
  font-family: "Hacen Liner XXL" ;
  src: url(./components/fonts/HacenLinerXXL.ttf);
}

.font{
  font-family: 'Cairo', sans-serif;
  /*font-family: "Hacen Liner Screen" ;*/
}

/* gallery viwer  */
.pswp-thumbnail {
  display: inline-block;
  
  margin: 5px 5px 5px 5px;
  cursor: pointer;
  
}
.pswp-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  
}
/*-------end gallery viewer-----------*/



.container-box{
  display: flex;
  flex-direction: row;
height: auto;
font-family: "Hacen Liner Screen Bd"
;
}
.right-box{
  width: 100%;
 margin-left:50px;
}
.left-box{
  width:500px;
 }
 .listItemText   {
   font-family:"Hacen Liner XL" ;
   font-size: 20px;
 }

 .headerTopSM{
   display:none !important
 }
 .headerTopLG{
  display:flex !important
 }
 .pubText{
   font-size: 18px;
   font-weight: bolder;
 }
 .ribText{
   font-size: 18px;
   font-weight: bold;
   display:flex;
   
 }
 .footerText{
  font-size: 16px;
  display:flex;
  flex-direction: row-reverse;
  
}
.contactText{
  font-size: 20px;
 }

 .mainHeaderText11{
  font-size: 60px;
 }

 .mainHeaderText1{
  font-size: 40px;
 }
 .mainHeaderText2{
  font-size: 25px;
 }
 .imgXS{
  margin: 20px;
}
.container-video{
padding-left: 100px;  
padding-right: 100px;  

}

.login-bgImage{
  height:"100%" ;
  width:"100%";
  background-image:url("./assets/bg2.png");
  background-color: "#D7DBDD";
  background-position: "center";
  background-repeat: "no-repeat";
  background-size: "cover";
  position: "relative"
}

.serviceCard{
  max-width: 280px;
}

.projectCard{
  max-width: 380px;
}

.boxLayout{
  padding-top:40px;
  padding-bottom: 40px;
}
.aboutBackground{
  /* background-image:url("./components/imges/about/Abstract-blue-wave-on-transparent-background-PNG.png");
  background-position: "center";
  background-repeat: "no-repeat";
  background-size:auto;
padding: 20px; */
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

 @media screen and (max-width :470px){
  .ribText{
    font-size: 12px;
    font-weight: bold;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  .pubText{
    font-size: 12px;
    font-weight: bolder;
  }
  .footerText{
    font-size: 14px;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  .listItemText {
    font-family:"Hacen Liner XL" ;
    font-size: 14px;
  }
  .contactText{
    font-size: 15px;
   }
   .mainHeaderText11{
    font-size: 40px;
   }
   .mainHeaderText1{
    font-size: 30px;
   }
   .mainHeaderText2{
    font-size: 20px;
   }
   
 }
 
 @media screen and (max-width :600px){
  .serviceCard{
    max-width: inherit;
  }
  .projectCard{
    max-width: inherit;
  }
 }

@media screen and (max-width :768px){
  .contactText{
    font-size: 18px;
   }
  .headerTopSM{
    display:flex !important
  }
  .listItemText {
    font-family:"Hacen Liner XL" ;
    font-size: 17px;
  }
  .headerTopLG{
    display:none !important
   }
  .right-box{
    width: 100%;
    margin-left:0px;
  }
  .left-box{
    width: 100%;
    
  }
  .container-box{
    display: flex;
    flex-direction: column-reverse;
  }
  .container-boxImages{
    display: flex;
    flex-direction: column-reverse;
  }
  .imgXS{
    margin-top: 60px ;
  }
  .container-video{
    padding-left: 0px;  
    padding-right: 0px;  
    
    }
    
    
}


.zoom {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.zoom:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}
.zoom2 {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.zoom2:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.responsive {
  width: 100%;
  height: auto;
}